import PropTypes from "prop-types"
import React from "react"
import WelcomeStyles from "../scss/components/welcome.module.scss";

const Welcome = ({ message }) => (
<div className={WelcomeStyles.welcome}>
  <h1 className={WelcomeStyles.heading}>
    <span className={WelcomeStyles.fade} aria-hidden="true">&lt;h1&gt;</span>
    {message}
    <span className={WelcomeStyles.fade} aria-hidden="true">&lt;/h1&gt;</span>
  </h1>

  <p>
    <span className={WelcomeStyles.fade} aria-hidden="true">&lt;p&gt;</span>
    My name is Andrew Iontton, Lead Front End Developer at
    <span className={WelcomeStyles.block}>
      <span className={WelcomeStyles.fade} aria-hidden="true">&lt;a href="https://clarity.global" target="_blank" rel="noreferrer noopener"&gt;</span>
      <a href="https://clarity.global" target="_blank" rel="noreferrer noopener">
      <u>Clarity Global</u>
      </a>
      <span className={WelcomeStyles.fade} aria-hidden="true">&lt;/a&gt;</span>
      <span className={WelcomeStyles.marker} />
    </span>
    <span className={WelcomeStyles.fade} aria-hidden="true">&lt;/p&gt;</span>
  </p>
</div>
)

Welcome.propTypes = {
  message: PropTypes.string,
}

Welcome.defaultProps = {
  message: `Hello World!`,
}

export default Welcome
