import React from "react"
import Layout from "../components/layout"
import Welcome from "../components/welcome"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Welcome message="Hello World!" />
  </Layout>
)

export default IndexPage
